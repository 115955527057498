import React from 'react';
import {withTranslation} from "react-i18next";
import Languages from "../components/form/Languages";
import moment from "moment";
import {apiRequest} from "../components/Globals";
import {Notify} from "../service/Notify";
import Pagination from "../components/Pagintations";
import ConfigService from "../service/ConfigService";
import {withRouter} from "../components/withRouter";
import {Auth} from "../components/Auth";
import {CURRENCIES_KEYS_IDS, IS_TERMS} from "../components/config/Globals";
import alertify from 'alertifyjs';

class Cabinet extends React.Component<{
    userInfo: {
        id: number,
        firstName: string;
        lastName: string;
        phone: string;
        units: number;
        balance: number;
        enabled: string;
        minPayment: number;
        daysCounter: number;
        tariff: {
            "id": number,
            "name": string,
            "costPerUnit": number,
        }
    },
    userHistory: {
        content: [{
            "id": string,
            "orderStatus": string,
            "amount": string,
            "currency": string,
            "createDateTime": string,
            "orderOperationOwner": string
        }]
    },
    navigate: Function,
    t: Function
}> {

    state = {
        settings: false,
        partMenu: 'account',
        isBurgerOpen: false,
        payPending: false
    }
    regularCb: boolean = true;

    configService: ConfigService;

    userHistory: any = [];
    pagination: Pagination = new Pagination();

    scrollingDebounce: NodeJS.Timeout = null;
    rangeInput: React.RefObject<any>;

    monthRange: number = 1;
    minPayment: number = null;

    paymentPayload: {
        merchantAccount: string;
        merchantAuthType: string;
        merchantDomainName: string;
        merchantSignature: string;
        orderReference: string;
        orderDate: string;
        amount: string;
        currency: string;
        orderTimeout: string;
        productName: string;
        productPrice: string;
        productCount: string;
        clientFirstName: string;
        clientLastName: string;
        clientAddress: string;
        clientCity: string;
        clientEmail: string;
        defaultPaymentSystem: string;
        serviceUrl: string;
        returnUrl: string;
    } = null;

    loading: number = 0;
    userInfo = null;
    min;
    max;
    step;
    payment = 0;

    terms: boolean = false;

    constructor(props) {
        super(props);

        this.rangeInput = React.createRef();
        this.configService = new ConfigService();

        this.handleScroll = this.handleScroll.bind(this);
        this.loadSearchResults = this.loadSearchResults.bind(this);
        this.doChangeMonth = this.doChangeMonth.bind(this);
        this.getSumValue = this.getSumValue.bind(this);
        this.doPay = this.doPay.bind(this);
        this.getAccCurrency = this.getAccCurrency.bind(this);
    }

    componentDidMount() {
        const isAcceptOffer = localStorage.getItem('terms');
        if (isAcceptOffer !== undefined) {
            this.terms = isAcceptOffer === '1';
        }

        if (this.terms) {
            alertify.alert()
                .setting({
                    'title': `${this.props.t('notification.title_newPlan')}`,
                    'message': `${this.props.t('notification.new_plan')} 
                            <a class="alert_link" href='/refresh-tariff/tarif2024'>${this.props.t('notification.link_word')}</a>`,
                }).show();
        }

        this.pagination.setPageSize(20);

        this.loading = 2;
        this.getUserData();
        this.doLoadHistory(true);
        // this.calculateMinMax();
    }

    componentDidUpdate() {
        if (this.state.partMenu === 'history') {
            window.addEventListener('scroll', () => this.scrollOnload(() => this.handleScroll(true), 100));
        } else {
            window.onscroll = null;
        }
    }

    doAfterPartsLoad() {
        if (this.loading > 0) {
            --this.loading;
        }

        if (this.loading > 0) {
            return;
        }

        this.calculateMinMax();
        this.setState({});
        setTimeout(() => {
            this.addScrollOnload();
        }, 300)
    }

    addScrollOnload() {
        let paymentsList = document.querySelector('#payments_history');

        if (paymentsList) {
            paymentsList.addEventListener("scroll", () => {
                this.scrollOnload(() => this.handleScroll(false), 100);
            });
        }
    }

    getUserData() {
        apiRequest('/user/info', '', "GET")
            .then((res: any) => {
                if (res) {
                    this.userInfo = res;
                }
                this.terms = res.oferta;

                if (res.oferta !== IS_TERMS.accepted) {
                    this.props.navigate('/terms');
                    return;
                }

                this.doAfterPartsLoad();

            }).catch(error => {
            console.error(error);
            Notify('error', error.message ? error.message : this.props.t('error.default'));
            if (!error.success) {
                this.props.navigate('/');
            }
        })
    }

    scrollOnload(callback: Function, time: number) {
        if (this.scrollingDebounce) {
            clearTimeout(this.scrollingDebounce);
        }

        this.scrollingDebounce = setTimeout(() => {
            callback();
        }, time)
    }

    calculateMinMax() {
        if (!this.userInfo) {
            return;
        }

        if ((this.userInfo.minPayment !== null && this.userInfo.minPayment !== undefined) && (this.userInfo.units !== undefined || true)
            && this.userInfo?.tariff.costPerUnit) {

            this.min = this.payment = this.userInfo.minPayment;
            this.max = this.userInfo.minPayment + (this.userInfo.units * this.userInfo.tariff.costPerUnit) * 23;
            this.step = (this.userInfo.units * this.userInfo.tariff.costPerUnit);
        } else if (this.userInfo.units && this.userInfo.tariff.costPerUnit) {
            this.min = this.payment = (this.userInfo.units * this.userInfo.tariff.costPerUnit);
            this.max = (this.userInfo.units * this.userInfo.tariff.costPerUnit) * 24;
            this.step = (this.userInfo.units * this.userInfo.tariff.costPerUnit);
        } else {
            Notify('error', this.props.t('error.not_calc'));
        }
    }

    handleScroll(isMobile: boolean) {
        let paymentsList = document.querySelector('#payments_history');

        if (paymentsList.scrollTop + paymentsList.clientHeight >= paymentsList.scrollHeight - 10 && !isMobile) {
            this.loadSearchResults();
        }

        if (isMobile && window.innerHeight + window.scrollY >= document.body.offsetHeight - 70 && isMobile) {
            this.loadSearchResults();
        }
    }

    loadSearchResults() {
        this.doLoadHistory(false);
        this.pagination.page++;
    };


    doLoadHistory(didMount: boolean) {
        if (!this.pagination.hasMore) {
            return;
        }

        let payload = {
            size: didMount ? 5 : this.pagination.pageSize,
            sort: 'created,desc',
            page: didMount ? 0 : this.pagination.page
        };


        apiRequest('/order/list', payload, "GET")
            .then((content: any) => {
                let list = [];

                if (!content) {
                    this.doAfterPartsLoad();
                    return;
                }
                // "rc_firm_id": "int",
                // "rc_reason": "string",


                content.forEach(payment => {
                    list.push({
                        id: payment.rc_id,
                        userId: payment.rc_user_id,
                        wialonUserId: payment.rc_contragent_id,
                        orderStatus: payment.rc_order_status,
                        orderOperationOwner: payment.rc_operation_owner,
                        orderOperation: payment.rc_operation,
                        amount: payment.rc_amount,
                        currency: CURRENCIES_KEYS_IDS[payment.rc_currency_id],
                        createDateTime: payment.rc_created,
                        updateDateTime: payment.rc_updated
                    })
                })

                if (this.pagination.page === 1) {
                    this.userHistory = [];
                }

                if (didMount) {
                    this.userHistory = list;
                    this.doAfterPartsLoad();
                } else {
                    this.userHistory = this.userHistory.concat(list);
                    this.setState({});
                }

            }).catch(error => {
            console.error(error);
            Notify('error', error.message ? error.message : this.props.t('error.default'));
        })
    }

    deletePayments() {
        apiRequest('/user/clear-pay-token')
            .then(response => {
                Notify('success', this.props.t('cabinet.deleted_success'));
                this.setState({partMenu: 'account', settings: false});

            }).catch(error => {
            console.error(error);
            Notify('error', error.message ? error.message : this.props.t('error.default'));
        })
    }

    doPay() {
        if (!this.configService.data) {
            Notify('error', this.props.t('error.not_pay'));
            return;
        }
        if (this.state.payPending) {
            return;
        }

        this.setState({payPending: true});

        const paySystem = this.configService.data.paymentSystem;

        if (paySystem === 'iPay') {
            this.makePayIPay();
        } else if (paySystem === 'PayU') {
            this.makePayU();
        } else {
            this.makePayWayForPay();
        }
    }

    makePayU() {
        let auth = new Auth();
        auth.load();

        let payload = {
            amount: (this.payment).toFixed(2),
            currency: CURRENCIES_KEYS_IDS[this.userInfo.tariff.currencyId],
            wialon_user_id: this.userInfo.id,
        }

        let init = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + auth.token,
            },
            body: null,
        };

        const replacer = (key, value) =>
            typeof value === 'undefined' ? null : value;

        init.body = JSON.stringify(payload, replacer);

        fetch(`${this.configService.data.phpApi}/order-pay?${this.configService.data.phpSuffix}`, init)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                console.error(response);
                Notify('error', this.props.t('error.default'));
                this.setState({payPending: false});

            })
            .then(res => {
                if (!res.data || res.success === false) {
                    console.error('cabinet_pay', res);
                    Notify('error', res.message ? res.message : this.props.t('error.default'));
                    this.setState({payPending: false});
                    return;
                }

                if (res.data && res.data.redirectLink) {
                    document.location.href = res.data.redirectLink;
                } else {
                    this.setState({payPending: false});
                }

            }).catch(error => {
            console.error(error);
            Notify('error', error.message ? error.message : this.props.t('error.default'));
        });
    }

    makePayWayForPay() {
        let payload = {
            // userId: this.userInfo.id,
            currency: CURRENCIES_KEYS_IDS[this.userInfo.tariff.currencyId],
            amount: (this.payment).toFixed(2),
            enableRegular: this.regularCb,
            regularityPeriod: this.monthRange
        }

        apiRequest('/payment/way-for-pay', payload)
            .then((response: any) => {
                if (!response) {
                    this.setState({payPending: false});
                    return;
                }

                this.createPayLink(response.body);
                this.setState({payPending: false});

            }).catch(error => {
            console.error(error);
            Notify('error', error.message ? error.message : this.props.t('error.default'));
            this.setState({payPending: false});
        })
    }

    makePayIPay() {
        let auth = new Auth();
        auth.load();

        let payload = {
            amount: (this.payment).toFixed(2),
            enableRegular: this.regularCb,
            regularityPeriod: this.monthRange,
            currency: this.getAccCurrency() !== "" ? this.getAccCurrency() : "EUR",
            productName: 'Data processing service',
            userId: this.userInfo.id,
            lang: auth.getCurrentLang(),
        }

        let init = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + auth.token,
            },
            body: null,
        };

        const replacer = (key, value) =>
            typeof value === 'undefined' ? null : value;

        init.body = JSON.stringify(payload, replacer);

        fetch(`${this.configService.data.phpApi}/payment/ipay-order?${this.configService.data.phpSuffix}`, init)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                console.error(response);
                Notify('error', this.props.t('error.default'));
                this.setState({payPending: false});

            })
            .then(res => {
                if (!res.data || res.success === false) {
                    console.error('cabinet_pay', res);
                    Notify('error', res.message ? res.message : this.props.t('error.default'));
                    this.setState({payPending: false});
                    return;
                }

                if (res.data && res.data.redirectLink) {
                    document.location.href = res.data.redirectLink;
                } else {
                    this.setState({payPending: false});
                }

            }).catch(error => {
            console.error(error);
            Notify('error', error.message ? error.message : this.props.t('error.default'));
        });
    }

    createPayLink(payData) {
        let payload = payData;

        delete payload.merchantTransactionType;
        delete payload.merchantTransactionSecureType;

        this.paymentPayload = payload;

        let button = document.getElementById('submitForm');

        setTimeout(() => {
            button.click();
        }, 500)
    }

    doChangeMonth() {
        if (this.userInfo && (this.userInfo.minPayment !== null && this.userInfo.minPayment !== undefined) && this.userInfo.units && this.userInfo.tariff.costPerUnit) {
            this.payment = Math.ceil((this.userInfo.minPayment + (Number(this.monthRange) - 1) * (this.userInfo.units * this.userInfo.tariff.costPerUnit)) * 100) / 100;
        } else if (this.userInfo && this.userInfo.units && this.userInfo.tariff.costPerUnit) {
            this.payment = Math.ceil((Number(this.monthRange) * (this.userInfo.units * this.userInfo.tariff.costPerUnit)) * 100) / 100;
        }
    }

    getSumValue(value) {
        if (value !== null && value !== undefined) {
            return String(Number(value).toFixed(2)).replace('.', ',');
        } else {
            return '';
        }
    }

    getAccCurrency() {
        let intCurId: number | string = this.userInfo?.tariff?.currencyId;
        let res = '';
        if (typeof intCurId === "string") {
            intCurId = parseInt(intCurId);
        }
        if (intCurId) {
            res = CURRENCIES_KEYS_IDS[intCurId];
        }
        return res;
    }

    render() {
        let t = this.props.t;
        let navigate: Function = this.props.navigate;

        if (this.loading !== 0) {
            return 'loading...'
        }

        if (!this.terms) {
            navigate('/terms');
            return;
        }

        return (
            <div className={'cabinet__main'}>
                <form style={{visibility: 'hidden', position: 'absolute', zIndex: -1}} method="post"
                      action="https://secure.wayforpay.com/pay">
                    <input defaultValue={this.paymentPayload?.merchantAccount} name="merchantAccount"/>
                    <input defaultValue={this.paymentPayload?.merchantAuthType} name="merchantAuthType"/>
                    <input defaultValue={this.paymentPayload?.merchantDomainName} name="merchantDomainName"/>
                    <input defaultValue={this.paymentPayload?.merchantSignature} name="merchantSignature"/>
                    <input defaultValue={this.paymentPayload?.orderReference} name="orderReference"/>
                    <input defaultValue={this.paymentPayload?.orderDate} name="orderDate"/>
                    <input defaultValue={this.paymentPayload?.amount} name="amount"/>
                    <input defaultValue={this.paymentPayload?.currency} name="currency"/>
                    <input defaultValue={this.paymentPayload?.orderTimeout} name="orderTimeout"/>
                    <input defaultValue={this.paymentPayload?.productName} name="productName"/>
                    <input defaultValue={this.paymentPayload?.productPrice} name="productPrice"/>
                    <input defaultValue={this.paymentPayload?.productCount} name="productCount"/>
                    <input defaultValue={this.paymentPayload?.clientFirstName} name="clientFirstName"/>
                    <input defaultValue={this.paymentPayload?.clientLastName} name="clientLastName"/>
                    <input defaultValue={this.paymentPayload?.clientAddress} name="clientAddress"/>
                    <input defaultValue={this.paymentPayload?.clientCity} name="clientCity"/>
                    <input defaultValue={this.paymentPayload?.clientEmail} name="clientEmail"/>
                    <input defaultValue={this.paymentPayload?.defaultPaymentSystem} name="defaultPaymentSystem"/>
                    <input defaultValue={this.paymentPayload?.serviceUrl} name="serviceUrl"/>
                    <input defaultValue={this.paymentPayload?.returnUrl} name="returnUrl"/>
                    <button type="submit" id="submitForm">Оплатить</button>
                </form>

                <div className="cabinet">
                    {window.innerWidth > 1250 &&
                        <Languages centered={this.state.settings}
                                   settingClick={() => this.setState({
                                       settings: !this.state.settings,
                                       partMenu: 'settings'
                                   })}/>}

                    <div className="container container--cabinet">
                        <div className="cabinet__inner">
                            <div className="cabinet__block">
                                <div onClick={() => this.setState({isBurgerOpen: true})} className="main-nav__toggle">
                                    <span className="line-burger"></span>
                                </div>
                                <ul className="cabinet__list">
                                    <li>
                                        <div onClick={() => this.setState({partMenu: 'account', settings: false})}
                                             className={`cabinet__link ${this.state.partMenu === 'account' ? 'cabinet__link--active' : ' '}`}>
                                            {t('cabinet.account')}
                                        </div>
                                    </li>
                                    <li>
                                        <div onClick={() => this.setState({partMenu: 'tariff', settings: false})}
                                             className={`cabinet__link ${this.state.partMenu === 'tariff' ? 'cabinet__link--active' : ' '}`}>
                                            {t('cabinet.tariff')}
                                        </div>
                                    </li>
                                    <li>
                                        <div onClick={() => this.setState({partMenu: 'history', settings: false})}
                                             className={`cabinet__link ${this.state.partMenu === 'history' ? 'cabinet__link--active' : ' '}`}>
                                            {t('cabinet.history')}
                                        </div>
                                    </li>
                                    <li>
                                        <div onClick={() => this.setState({partMenu: 'settings', settings: true})}
                                             className={`cabinet__link ${this.state.partMenu === 'settings' ? 'cabinet__link--active' : ' '}`}>
                                            {t('cabinet.setting')}
                                        </div>
                                    </li>
                                    <li>
                                        {window.innerWidth < 1250 &&
                                            <Languages centered={this.state.settings}
                                                       settingClick={() => this.setState({
                                                           settings: !this.state.settings,
                                                           partMenu: 'settings'
                                                       })}
                                            />}
                                    </li>
                                </ul>
                            </div>

                            <div className="cabinet__wrap">
                                <div
                                    className={`cabinet__item simple-item ${this.state.partMenu === 'account' ? 'cabinet__item--active' : ''}
                                ${this.state.settings === true ? 'cabinet__hide' : ''}`}>

                                    <div className="cabinet__holder"><h3
                                        className="cabinet__title">{t('cabinet.account')}</h3>
                                        <p className={`cabinet__active ${(this.userInfo && parseInt(this.userInfo.enabled)) === 1 ? "active" : "inactive"}`}>
                                            {t(`cabinet.${(this.userInfo && parseInt(this.userInfo.enabled)) === 1 ? "active" : "inactive"}`)}
                                        </p>
                                    </div>
                                    <section className="inner-box">
                                        <div className="account">
                                            <div className="payment__holder">
                                                <div className="payment__block">
                                                    <div className={'payment__acc-balance'}>
                                                        {t('cabinet.balance')}
                                                        <span className={'payment__sum'}>
                                                    {this.userInfo && this.userInfo.balance.toFixed(2)}
                                                            <span
                                                                className={'payment__currency'}> {t(`cabinet.${this.getAccCurrency()}`)}.</span>
                                                    </span>
                                                    </div>
                                                </div>

                                                <div className="payment__block">
                                                    <div
                                                        onClick={() => this.setState({
                                                            partMenu: 'tariff',
                                                            settings: false
                                                        })}
                                                        className="payment__btn">
                                                        {t('cabinet.pay')}
                                                    </div>
                                                </div>
                                            </div>

                                            {this.userInfo && <div className="payment__user-info-block">
                                                <div>
                                                    <div className={'payment__acc-info'}>
                                                        {t('cabinet.fio')}:
                                                        <span className={'payment__user-info'}>
                                                    {this.userInfo.firstName} {this.userInfo.lastName}
                                                    </span>
                                                    </div>
                                                    <div className={'payment__acc-info'}>
                                                        {t('cabinet.phone')}:
                                                        <span className={'payment__user-info'}>
                                                    {this.userInfo.phone}</span>
                                                    </div>
                                                    <div className={'payment__acc-info'}>
                                                        {t('cabinet.objectsNumber')}:
                                                        <span className={'payment__user-info'}>
                                                    {this.userInfo.units}
                                                    </span>
                                                    </div>
                                                </div>
                                            </div>}

                                        </div>
                                    </section>

                                </div>
                                <div
                                    className={`cabinet__item simple-item ${this.state.partMenu === 'tariff' ? 'cabinet__item--active' : ''}
                                ${this.state.settings === true ? 'cabinet__hide' : ''}`}>

                                    <div className="cabinet__holder"><h3
                                        className="cabinet__title">{t('cabinet.tariff')}</h3></div>
                                    {this.userInfo && this.userInfo.tariff && <section className="inner-box">
                                        <div className="plan">
                                            <h4 className="plan__title">{t('cabinet.activeTariff')}:</h4>
                                            <p className="plan__rate">«{this.userInfo.tariff.name.toUpperCase()}»</p>

                                            <div className="plan__holder">
                                                <p className="plan__text">
                                                    {t('cabinet.minPay')}
                                                </p>
                                                <p className="plan__price">
                                                    <span>{this.userInfo.minPayment.toFixed(2)}</span>{this.getAccCurrency()}
                                                </p>
                                            </div>
                                            <p className="plan__sum">{t('cabinet.payment')}: <span>
                                            <b>{this.getSumValue(this.payment)}</b>{this.getAccCurrency()}</span>
                                                <b>({t('cabinet.on')} {this.monthRange} {t('cabinet.monthes')})</b>
                                            </p>
                                            <div className="plan__slider">
                                                <div className="plan__wrap">
                                                    <button
                                                        onClick={() => {
                                                            this.rangeInput.current.value--;
                                                            this.monthRange = this.rangeInput.current.value;
                                                            this.doChangeMonth();
                                                            this.setState({});
                                                        }}
                                                        className="plan__arrow plan__arrow--minus"></button>
                                                    <button
                                                        onClick={() => {
                                                            this.rangeInput.current.value++;
                                                            this.monthRange = this.rangeInput.current.value;
                                                            this.doChangeMonth();
                                                            this.setState({});
                                                        }}
                                                        className="plan__arrow plan__arrow--plus"></button>
                                                </div>
                                                <div className="plan__cost">
                                                    <input
                                                        className="ng-untouched ng-pristine ng-valid"
                                                        type="range"
                                                        ref={this.rangeInput}
                                                        id="volume"
                                                        name="volume"
                                                        onChange={(e) => {
                                                            this.monthRange = parseFloat(e.target.value);
                                                            this.doChangeMonth();
                                                            this.setState({});
                                                        }}
                                                        min="1"
                                                        max="24"
                                                        defaultValue={1}
                                                    />
                                                </div>
                                                <div className="plan__block">
                                                    <div className="plan__data"><span
                                                    >{this.getSumValue(this.min)}</span> {this.getAccCurrency()}
                                                    </div>
                                                    <div className="plan__data"><span
                                                    >{this.getSumValue(this.max)}</span> {this.getAccCurrency()}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="bar"></div>
                                            <button onClick={() => this.doPay()}
                                                    disabled={this.state.payPending || this.payment === 0}
                                                    className={`plan__btn ${this.state.payPending || this.payment === 0 ? 'disabled' : ''}`}>
                                                {t('cabinet.submitPay')}
                                            </button>
                                            <div style={{paddingTop: "20px"}}>
                                                <div className="flexed-box">
                                                    <div className="check-box">
                                                        <div className={`checkbox ${this.regularCb ? 'checked' : ''}`}
                                                             onClick={() => {
                                                                 this.regularCb = !this.regularCb;
                                                                 this.setState({});
                                                             }}
                                                        >
                                                        </div>
                                                    </div>
                                                    <div className="placeholder-box">
                                                        <div className="placeholder-text">
                                                        <span
                                                            dangerouslySetInnerHTML={{__html: t('cabinet.makeRegular')}}
                                                        ></span></div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </section>}
                                </div>
                                <div
                                    className={`cabinet__item simple-item ${this.state.partMenu === 'history' ? 'cabinet__item--active' : ''}
                                                             ${this.state.settings === true ? 'cabinet__hide' : ''}`}>

                                    <div className="cabinet__holder"><h3
                                        className="cabinet__title">{t('cabinet.history')}</h3></div>
                                    <section id={'payments_history'} className="inner-box">
                                        <div className="payment">
                                            <div className="payment__wrap">

                                                {this.userHistory.map((payment, key) => {
                                                    return (
                                                        <div key={payment.id}
                                                             className={`${payment.orderStatus} payment__item`}>
                                                            <div className="payment__inner">
                                                                <div className="payment__box"><p
                                                                    className="payment__title">{t('cabinet.date')}:</p>
                                                                    <p className="payment__data">
                                                                        {moment(payment.createDateTime).format('DD.MM.YYYY HH:mm')}</p>
                                                                </div>
                                                                <div className="payment__box"><p
                                                                    className="payment__title">{t('cabinet.status')}:</p>

                                                                    <p className="payment__status">
                                                            <span className={payment.orderStatus}>
                                                                {t(`cabinet.${payment.orderStatus.toLowerCase()}`)}
                                                            </span>
                                                                    </p>
                                                                </div>
                                                                <div className="payment__box"><p
                                                                    className="payment__title">{t('cabinet.sum')}</p>
                                                                    <p className="payment__count"
                                                                       style={{color: payment.orderStatus === "REFUNDED" ? '#ce4b44' : '#3dce66'}}>
                                                                        <span>{payment.amount ? (parseFloat(payment.amount)).toFixed(2) : 0}</span>
                                                                        &nbsp;{payment.currency ? t(`cabinet.${payment.currency}`) : '___'}
                                                                    </p>
                                                                </div>
                                                                <div className="payment__box"><p
                                                                    className="payment__title">{t('cabinet.initiator')}</p>
                                                                    <p
                                                                        className="payment__count">{t(`cabinet.${payment.orderOperationOwner}`)}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </section>
                                </div>

                                {this.state.settings && <div
                                    className={`cabinet__item setup ${this.state.partMenu === 'settings' && 'cabinet__item--active'}`}>
                                    <div className="cabinet__holder"><h3
                                        className="cabinet__title">{t('cabinet.setting')}</h3>
                                    </div>
                                    <section className="inner-box">
                                        <div className="payment">
                                            <div className="payment__wrap">
                                                <p className={'setting__msg'}>{t('cabinet.delete_alert')}</p>
                                                <div>
                                                    <button
                                                        onClick={() => this.deletePayments()}
                                                        className="btn btn-danger">{t('cabinet.delete')}</button>
                                                    &nbsp;
                                                    <button
                                                        onClick={() => this.setState({
                                                            partMenu: 'account',
                                                            settings: false
                                                        })}
                                                        className="btn btn-success cancel-setup">{t('cabinet.unConfirm')}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>}
                            </div>

                            <div className="popup">
                                <div className="popup__block">
                                    <button className="popup__back"></button>
                                    <ul className="popup__list">
                                        <li>
                                            <div className="cabinet__link cabinet__link--active">{t('cabinet.account')}
                                            </div>
                                        </li>
                                        <li>
                                            <div className="cabinet__link">{t('cabinet.tariff')}</div>
                                        </li>
                                        <li>
                                            <div className="cabinet__link">{t('cabinet.history')}</div>
                                        </li>
                                        <li>
                                            <div className="cabinet__link">{t('cabinet.setting')}</div>
                                        </li>

                                        <li>
                                            <div className="cabinet__link logout">{t('cabinet.logout')}</div>
                                        </li>
                                        <li>
                                            <div className="lang">
                                                {window.innerWidth < 1250 &&
                                                    <Languages centered={this.state.settings}
                                                               settingClick={() => this.setState({
                                                                   settings: !this.state.settings,
                                                                   partMenu: 'settings'
                                                               })}
                                                    />}
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className={`popup ${this.state.isBurgerOpen ? 'popup--active' : ''}`}>
                                <div className="popup__block popup__block--active">
                                    <button onClick={() => this.setState({isBurgerOpen: false})}
                                            className="popup__back"></button>
                                    <ul className="popup__list">
                                        <li>
                                            <div onClick={() => this.setState({
                                                partMenu: 'account',
                                                settings: false,
                                                isBurgerOpen: false
                                            })}
                                                 className={`cabinet__link ${this.state.partMenu === 'account' ? 'cabinet__link--active' : ' '}`}>
                                                {t('cabinet.account')}
                                            </div>
                                        </li>
                                        <li>
                                            <div onClick={() => this.setState({
                                                partMenu: 'tariff',
                                                settings: false,
                                                isBurgerOpen: false
                                            })}
                                                 className={`cabinet__link ${this.state.partMenu === 'tariff' ? 'cabinet__link--active' : ' '}`}>
                                                {t('cabinet.tariff')}
                                            </div>
                                        </li>
                                        <li>
                                            <div onClick={() => this.setState({
                                                partMenu: 'history',
                                                settings: false,
                                                isBurgerOpen: false
                                            })}
                                                 className={`cabinet__link ${this.state.partMenu === 'history' ? 'cabinet__link--active' : ' '}`}>
                                                {t('cabinet.history')}
                                            </div>
                                        </li>
                                        <li>
                                            <div onClick={() => this.setState({
                                                partMenu: 'settings',
                                                settings: true,
                                                isBurgerOpen: false
                                            })}
                                                 className={`cabinet__link ${this.state.partMenu === 'settings' ? 'cabinet__link--active' : ' '}`}>
                                                {t('cabinet.setting')}
                                            </div>
                                        </li>
                                        <li>
                                            <div className="lang">
                                                {window.innerWidth < 1250 &&
                                                    <Languages centered={this.state.settings}
                                                               settingClick={() => this.setState({
                                                                   settings: !this.state.settings,
                                                                   partMenu: 'settings'
                                                               })}
                                                    />}
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>

        )
    }
}

export default withTranslation()(withRouter(Cabinet));