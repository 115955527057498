import React from 'react';
import {withTranslation} from "react-i18next";
import {withRouter} from "../components/withRouter";
import Languages from "../components/form/Languages";


class Informer extends React.Component<any, any> {
    state = {};

    componentDidMount() {
        this.setState({});
    }

    render() {
        let t: Function = this.props.t;
        let navigate: Function = this.props.navigate;
        let {hash} = this.props.useParams;

        return (
            <div className="rates">
                <h3 className="login__subtitle login__subtitle--in">
                    {t('rates.title', {ns: hash})}
                </h3>

                <div className="login__languages jcc languages mb-3">
                    <Languages hideExit={true} centered={true} refresh={() => this.setState({})}/>
                </div>

                <div>
                    <div dangerouslySetInnerHTML={{__html: t('rates.text', {ns: hash})}}>
                    </div>

                    <div className="login__inner jcc mb-1">
                        <button onClick={() => navigate('/')}
                                className="login__box login__box--answer">{t('rates.btn_back', {ns: hash})}
                        </button>
                    </div>

                    <span>
                        {t('rates.footer_ps')}
                    </span>
                </div>
            </div>
        );
    }
}


export default withTranslation(['tarif2023'])(withRouter(Informer));
