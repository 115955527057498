import React, {Component, Suspense} from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import Login from "./pages/Login";
import Rates from "./pages/Rates";
import TermsAndConditions from "./pages/TermsAndCond";
import Cabinet from "./pages/Cabinet";
import ConfigService from "./service/ConfigService";
import Register from "./pages/Register";
import ForgotPassword from "./pages/forgotPassword/ForgotPassword";
import './assets/alertifyjs/css/alertify.min.css'
import {Auth} from "./components/Auth";
import {withRouter} from "./components/withRouter";
import RefreshTariff from "./pages/RefreshTariff";


class App extends Component<any, any> {
    config: any = null;
    auth: Auth;

    constructor(props) {
        super(props);
        this.config = new ConfigService(true);
        this.auth = new Auth();
        this.auth.load();
    }


    render() {

        return (
            <Suspense fallback={'...loading'}>
                <Routes>
                    <Route path="/" element={<Login isAuth={() => this.auth.load()}/>}/>
                    <Route path="/terms" element={<TermsAndConditions/>}/>
                    {this.auth.isAuthorized() && <Route path="/cabinet" element={<Cabinet/>}/>}
                    <Route path="/register" element={<Register/>}/>
                    <Route path="/password" element={<ForgotPassword/>}/>
                    <Route path="/informer/:hash" element={<Rates/>}/>
                    <Route path="/refresh-tariff/:hash" element={<RefreshTariff/>}/>

                    <Route path="*" element={<Navigate to="/" replace/>}/>
                </Routes>
            </Suspense>
        );
    }
}

export default withRouter(App);